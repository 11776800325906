/**
 * @deprecated
 * This file is preserved for backwards compatability, but future users should import from:
 * - integrations/shopify
 * - integration/utils
 * */

import { waitForElement } from "../../shared/wait-for-element";
import { getCookieMap, getCookieValue } from "../../shared/cookies";

const utils = {
  waitForElement,
  getCookieMap,
  getCookieValue,
};

export { utils };
export { initNetworkListeners } from "../../shared/network-listeners";
export * as events from "../../shared/events";

export * from "../../integrations/shopify";
