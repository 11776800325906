import "../../components/post-purchase";
import { postTransaction } from "../../api-sdk/v3/routes";
import { DEFAULT_BASE_URL } from "../../api-sdk/constants";
import { LANGUAGES, TUrl } from "../../api-sdk/types";
import { waitForElement } from "../../shared/wait-for-element";
import { BeamError } from "../../shared/beam-errors";
import { getCookieValue } from "../../shared/cookies";
import { createScopedLocalStorage } from "../../shared/local-storage";

// Shopify admin page: admin/settings/checkout

export type TBeamOrderPageParams = Parameters<typeof postTransaction>[0]["requestBody"] & {
  // Either storeId or postalCode + countryCode must be provided
  storeId?: number;
  postalCode?: string;
  countryCode?: string;
  orderId: string;
  email: string;
  cartTotal: number;
  currencyCode: string;
  parentSelector: string; // CSS Selector for parent of widget to be inserted into DOM
  apiKey: string;
  baseUrl: TUrl;
  lang: LANGUAGES;
  debug: boolean;
};

export async function showBeamOrderPageWidgets({
  parentSelector = ".step__sections",
  apiKey,
  baseUrl = DEFAULT_BASE_URL,
  storeId,
  postalCode,
  countryCode,
  orderId,
  email,
  cartTotal,
  currencyCode = "USD",
  lang = "en",
  debug = false,
}: TBeamOrderPageParams) {
  const parentElement = document.querySelector(parentSelector) || (await waitForElement(parentSelector));
  if (!parentElement) {
    throw new BeamError(
      `Timed out waiting for selector '${parentSelector}'. Could not render Beam post-purchase widget.`
    );
  }

  // If checkout and order page are on different subdomains, we can't access localStorage
  // We can make postTransaction work by copying the cart cookie to the expected localStorage key on the current domain
  const cartId = await getCookieValue("cart"); // Shopify-only cart identifier
  if (cartId) {
    const beamLocalStorage = createScopedLocalStorage({ apiKey });
    beamLocalStorage.setItemJson("cart", { cartId });
  }

  // Note: assign properties one by one instead of bulk Object.assign to type check assignments
  const widget = document.createElement("beam-post-purchase");
  widget.apiKey = apiKey;
  widget.baseUrl = baseUrl;
  widget.storeId = storeId;
  widget.postalCode = postalCode;
  widget.countryCode = countryCode;
  widget.orderId = String(orderId);
  widget.email = email;
  widget.cartTotal = cartTotal;
  widget.currencyCode = currencyCode;
  widget.lang = lang;
  widget.debug = debug;

  // Insert widget into DOM
  parentElement.appendChild(widget);
}
