import { getCookieValue } from "../../shared/cookies";
import { postCart } from "../../api-sdk/v3/routes";
import { DEFAULT_BASE_URL } from "../../api-sdk/constants";
import { initNetworkListeners } from "../../shared/network-listeners";
import { BeamNetworkCallEvent, BeamNonprofitSelectEvent } from "../../shared/events";
import { createScopedLocalStorage } from "../../shared/local-storage";

type BeamConfig = { apiKey: string; storeId: number; chainId?: number; baseUrl?: string };

let isBeamCartIntegrationRegistered = false; // Skip registering event listeners if already set up

/**
 * registerCartIntegration - set up event listeners to integrate Beam with Shopify cart
 * @return {boolean} returns true if NEW listeners were created, false if setup was already done
 */
async function registerCartIntegration(config: BeamConfig) {
  if (isBeamCartIntegrationRegistered) {
    return false; // Do nothing if listeners are already attached
  }

  // Handle full-page load, ie, first-time page load and reloads from form submit events
  // This creates a GET to /cart.js
  const currentCart = await getCurrentCart(config);
  if (currentCart.changed) {
    await trackCart(config, currentCart.cart);
  }

  // Set up event listeners for AJAX events
  initNetworkListeners();

  window.addEventListener(BeamNetworkCallEvent.eventName, async (_event: Event) => {
    const event = _event as BeamNetworkCallEvent;
    const cartChangePaths = /cart\/(add|change|update|clear)\.js$/;
    // note: response urls below follow redirects
    const requestUrlStr = event.detail.type === "xhr" ? event.detail.xhr.responseURL : event.detail.response.url;
    const url = new URL(requestUrlStr);
    if (cartChangePaths.test(url.pathname)) {
      // Note: ignore change boolean, we know a change happened even if item count/value is the same
      const currentCart = await getCurrentCart(config);
      // Sending request will update timestamp which is used for cart abandonment calc
      await trackCart(config, currentCart.cart);
    }
  });

  // Listen to Beam's own events to integrate Beam data into Shopify order
  window.addEventListener(BeamNonprofitSelectEvent.eventName, async (_event) => {
    const event = _event as BeamNonprofitSelectEvent;
    const { selectedNonprofitId, selectionId } = event.detail;
    const cartId = await getCookieValue("cart");
    const { chainId, storeId } = config;
    await addBeamAttributesToCart({
      selectedNonprofitId,
      selectionId,
      cartId,
      chainId,
      storeId,
    });
  });

  isBeamCartIntegrationRegistered = true;

  return true;
}

// Shopify dynamic values come from window global:
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Shopify?: any;
  }
}
const SHOPIFY_BASE_URL = window.Shopify?.routes?.root || "/";

/**
 * Detects change in cart since last page load,
 * by calling GET /cart.js, and returns cart values.
 * Used for:
 *  - Form-based carts where the page refreshes to modify cart instead of using AJAX calls
 *  - Hydrogen/GraphQL based carts (needs to be integrated manually)
 */
async function getCurrentCart(config: BeamConfig): Promise<{
  changed: boolean;
  cart: { cartId: string; subtotal: number; itemCount: number; currencyCode: string };
}> {
  const {
    token: cartId, // equal to "cart" cookie
    total_price: subtotal, // this is the pretax value, after discounts, and multiplied by 100 (integer from decimal)
    item_count: itemCount,
    currency: currencyCode,
  } = await window
    .fetch(SHOPIFY_BASE_URL + "cart.js", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => res.json());

  const cart = { cartId, subtotal: subtotal / 100, itemCount, currencyCode };

  let changed = false;

  const localStorage = createScopedLocalStorage(config);

  const cartJsonString = JSON.stringify(cart, null, 0);
  const cachedCartJsonString = localStorage.getItem("cart");

  if (cartJsonString !== cachedCartJsonString) {
    localStorage.setItem("cart", cartJsonString);
    localStorage.setItem("cart_timestamp", new Date().toISOString());
    changed = true;
  }

  return { changed, cart };
}

/** Sends cart information to Beam for ROI tracking */
async function trackCart(
  config: BeamConfig,
  values: {
    cartId: string;
    itemCount?: number;
    subtotal?: number;
    currencyCode?: string;
  }
) {
  try {
    const baseUrl = config.baseUrl || DEFAULT_BASE_URL;
    await postCart({
      baseUrl,
      headers: {
        authorization: `Api-Key ${config.apiKey}`,
      },
      requestBody: {
        storeId: config.storeId,
        ...values,
      },
    });
  } catch (err) {
    console.error(err); // don't throw, allow later calls to continue
  }
}

/** Sends Beam data to Shopify to integrate with order as custom attributes */
async function addBeamAttributesToCart({
  selectedNonprofitId,
  selectionId,
  cartId,
  chainId,
  storeId,
}: {
  selectedNonprofitId?: number;
  selectionId?: string;
  cartId?: string;
  chainId?: number;
  storeId?: number;
}) {
  const beamCartAttrs = {
    beam_nonprofit_id: selectedNonprofitId,
    beam_selection_id: selectionId,
    beam_cart_id: cartId,
    beam_chain_id: chainId,
    beam_store_id: storeId,
  };

  try {
    await window.fetch(SHOPIFY_BASE_URL + "cart/update.js", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ attributes: beamCartAttrs }),
    });
  } catch (err) {
    console.error(err); // don't throw, allow later calls to continue
  }
}

export { trackCart, getCurrentCart, addBeamAttributesToCart, registerCartIntegration };
