import { BeamNetworkCallEvent } from "./events";

/**
 * Fetch Listener
 */

const originalFetch = window.fetch;

const fetchHandler = {
  apply: function (target: any, _thisArg: any, args: any[]) {
    return target(...args).then((response: Response) => {
      if (response.ok) {
        const responseCopy = response.clone();
        window.dispatchEvent(
          new BeamNetworkCallEvent({
            url: args[0].toString(),
            method: (args[1]?.method || "get").toLowerCase(),
            type: "fetch",
            response: responseCopy,
          })
        );
      }
      return response;
    });
  },
};

export const fetchProxy = new Proxy(originalFetch, fetchHandler);

/**
 * XMLHTTPRequest Listener
 */

const originalXhr = XMLHttpRequest;

const xhrHandler = {
  construct(TargetConstructor: any, args: any[]) {
    const xhr: XMLHttpRequest = new TargetConstructor(...args);
    xhr.addEventListener("load", () => {
      window.dispatchEvent(
        new BeamNetworkCallEvent({
          url: (args[1] || "").toString(),
          method: (args[0] || "get").toLowerCase(),
          type: "xhr",
          xhr,
        })
      );
    });
    return xhr;
  },
};

export const xhrProxy = new Proxy(originalXhr, xhrHandler);

/**
 * Side-Effects Setup (initialize listeners)
 */

let initializedListeners = false;

export const initNetworkListeners = () => {
  if (initializedListeners) return;
  window.fetch = fetchProxy;
  XMLHttpRequest = xhrProxy;
  initializedListeners = true;
};
